// Collpase

[data-toggle="collapse"] {
  &.collapsed .if-not-collapsed {
    display: none;
  }

  &:not(.collapsed) .if-collapsed {
    display: none;
  }
}