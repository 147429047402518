// Add positions

.left-0 {
  left: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-100 {
  left: 100%;
}

.top-100 {
  top: 100%;
}

.right-100 {
  right: 100%;
}

.bottom-100 {
  bottom: 100%;
}

.top-centered {
  top: 50%;
  transform: translateY(-50%);
}