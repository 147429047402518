// stylelint-disable declaration-no-important

// Add breakpoins to width and height

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  
      @each $prop, $abbrev in (width: w, height: h) {
        @each $size, $length in $sizes {
          .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
        }
      }
    }
  }
  
  .min-w-100 {
    min-width: 100% !important;
  }
  
  .min-w-0 {
    min-width: 0 !important;
  }

  .font-size-base {
    font-size: $font-size-base;
  }

  .font-size-sm {
    font-size: $font-size-sm;
  }

  .font-size-lg {
    font-size: $font-size-lg;
  }