// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:                             #fff;
$white-opacity-18:                  rgba($white, 0.18);

$gray-dark:                         hsl(0, 0%, 20%);   // #333333
$gray:                              #5A7785;   // #4a4a4a
$gray-soft:                         hsl(0, 0%, 49%);   // #7d7d7d
$gray-softer:                       hsl(0, 0%, 60%);   // #999999
$gray-light:                        hsl(0, 0%, 84%);   // #d6d6d6
$gray-lighter:                      hsl(0, 0%, 94%);   // #f0f0f0

$blue:                              #008CD2;
$blue-light:                        #f0f8fd;
$indigo:                            #0A416E;
$indigo-light:                      #d7e0e8;
$indigo-dark:                       #083051;
$purple:                            #90278D;
$deep-purple:                       #662D8F;
$green:                             #8AC647;

$success:                           #28a745;
$danger:                            #dc3545;
$info:                              #17a2b8;
$warning:                           #ffc107;

$colors: (
  "purple":                         $purple,
  "deeppurple":                     $deep-purple,
);

$bg-colors: (
  "bluelight":                      $blue-light,
  "indigodark":                     $indigo-dark,
  "indigolight":                    $indigo-light,
  "purple":                         $purple,
  "deeppurple":                     $deep-purple,
);

$theme-colors: (
  "primary":                        $blue,
  "secondary":                      $indigo,
  "success":                        $success,
  "danger":                         $danger,
  "info":                           $info,
  "warning":                        $warning,
  "gray":                           $gray,
  "bluelight":                      $blue-light
);

$primary:                           theme-color("primary");
$secondary:                         theme-color("secondary");

// Options

$enable-rounded:                    true;
$enable-shadows:                    true;

// Body

$body-bg:                           $white;
$body-color:                        $gray;

// Links

$link-color:                        $primary;
$link-hover-color:                  $secondary;
$link-hover-decoration:             none;

// Paragraphs

$paragraph-margin-bottom:           0;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xl: 1140px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Components

$border-radius:                     .25rem;
$border-radius-lg:                  .5rem;

$box-shadow-sm:                     0 .25rem .625rem rgba($black, .18);
$box-shadow:                        0 .25rem .625rem rgba($black, .28);
$box-shadow-lg:                     0 .375rem .625rem rgba($black, .4);

// Typography

$font-size-base:                    1rem;                      // 16px
$font-size-sm:                      $font-size-base * 0.875;   // 14px
$font-size-lg:                      $font-size-base * 1.125;   // 18px

$font-weight-light:                 300 !default;
$font-weight-normal:                400 !default;
$font-weight-medium:                500;
$font-weight-semi-bold:             600;
$font-weight-bold:                  700 !default;

$h1-font-size:                      $font-size-base * 2;        // 32px
$h2-font-size:                      $font-size-base * 1.5;      // 24px
$h3-font-size:                      $font-size-base * 1.25;     // 20px
$h4-font-size:                      $font-size-base * 1.125;    // 18px
$h5-font-size:                      $font-size-base * 1;        // 16px
$h6-font-size:                      $font-size-base * 0.875;    // 14px

$font-family-sans-serif:            "Raleway", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-alt:                   "Poppins", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-base:                  $font-family-sans-serif;

$headings-font-family:              $font-family-alt;
$headings-font-weight:              $font-weight-semi-bold;
$headings-color:                    $secondary;
$headings-margin-bottom:            0;

// Buttons + Forms

$input-btn-font-family:       $font-family-alt;

$input-btn-focus-width:       0;
$input-btn-focus-box-shadow:  none;

// Buttons

$btn-font-weight:             $font-weight-bold;
$btn-box-shadow:              $box-shadow-sm;
$btn-focus-width:             $input-btn-focus-width;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$btn-active-box-shadow:       $input-btn-focus-box-shadow;

$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius-lg;

$btn-padding-y:               0.5rem;
$btn-padding-x:               2rem;
$btn-font-size:               $font-size-base;

$btn-padding-y-lg:            1rem;
$btn-padding-x-lg:            2.5rem;
$btn-font-size-lg:            $font-size-lg;

// Forms

$input-bg:                              $blue-light;
$input-color:                           $secondary;

$input-border-width:                    0;

$input-focus-bg:                        $input-bg;
$input-focus-color:                     $input-color;
$input-focus-width:                     0;
$input-focus-box-shadow:                none;

$custom-control-label-color:            $secondary;

$custom-control-indicator-size:         1.25rem;
$custom-control-indicator-bg:           $white;
$custom-control-indicator-border-color: $secondary;
$custom-control-indicator-border-width: 1px;

$custom-control-indicator-checked-bg:           $primary;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow:     $white;
$custom-control-indicator-focus-border-color:   $secondary;

$custom-checkbox-indicator-border-radius:       $border-radius;
$custom-checkbox-indicator-icon-checked:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='11.63' height='9.207' viewBox='0 0 11.63 9.207'> <path fill='#{$custom-control-indicator-checked-color}' d='M9.83,2,4.361,7.605,1.8,5.177,0,6.978l4.361,4.23L11.63,3.8Z' transform='translate(0 -2)' /></svg>");

// Navs

$nav-link-padding-y:                1.5rem;
$nav-link-padding-x:                1.125rem;

$nav-tabs-border-color:             $gray-light;
$nav-tabs-border-width:             1px;
$nav-tabs-border-radius:            $border-radius;
$nav-tabs-link-hover-border-color:  $gray-light;
$nav-tabs-link-active-color:        $white;
$nav-tabs-link-active-bg:           $primary;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg;

// Navbar

$navbar-padding-y:                  0;
$navbar-padding-x:                  0;

$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           rgba($white, 1);
$navbar-dark-active-color:          $primary;
$navbar-dark-disabled-color:        rgba($white, .25);

$navbar-nav-link-padding-x:         2.5rem;
$navbar-dark-toggler-border-color:  transparent;

// Dropdowns

$dropdown-min-width:                12rem;
$dropdown-padding-x:                0;
$dropdown-padding-y:                .5rem;
$dropdown-spacer:                   0;
$dropdown-color:                    $white;
$dropdown-bg:                       $primary;
$dropdown-border-width:             0;
$dropdown-box-shadow:               $box-shadow-sm;

$dropdown-link-color:               rgba($white, 0.8);
$dropdown-link-hover-color:         rgba($white, 1);
$dropdown-link-hover-bg:            $dropdown-bg;

$dropdown-link-active-color:        $white;
$dropdown-link-active-bg:           darken($primary, 20%);

$dropdown-item-padding-y:           .5rem;
$dropdown-item-padding-x:           1rem;

// Pagination


$pagination-padding-y-lg:           .375rem;
$pagination-padding-x-lg:           1rem;

$pagination-color:                  $white;
$pagination-bg:                     $primary;
$pagination-border-color:           $primary;

$pagination-hover-color:            $white;
$pagination-hover-bg:               $secondary;
$pagination-hover-border-color:     $secondary;

$pagination-active-color:           $pagination-hover-color;
$pagination-active-bg:              $pagination-hover-bg;
$pagination-active-border-color:    $pagination-hover-border-color;

$pagination-disabled-color:         $white;
$pagination-disabled-bg:            darken($gray-light, 20%); //lighten($primary, 20%);
$pagination-disabled-border-color:  darken($gray-light, 20%);

// Modals
$modal-content-color:               $secondary;
$modal-backdrop-bg:                 rgba($secondary, 0.6);
$modal-content-border-radius:       1rem;
$modal-header-border-width:         0;
$modal-footer-border-width:         0;

// Tables
$table-dark-bg:               $secondary;


// Forms

$custom-file-text: (
  en: "Browse",
  cs: "Procházet"
);