// icons sizes

$icon-sizes: (
  xsmall:   15px,
  small:    18px,
  default:  33px,
  medium:   38px,
  large:    43px,
  xlarge:   48px
);

@each $size, $value in $icon-sizes {
  .icon-#{$size} {
    width: $value;
    height: $value;
  }
}