.toast-container {
    > div {
        transition: $transition-base !important;
        border-radius: $border-radius !important;
        overflow: hidden !important;
        box-shadow: $box-shadow !important;

        &:hover {
            box-shadow: $box-shadow-lg !important;
        }
    }
}