.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover, .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border-color: $primary !important;
}

.vdp-datepicker__calendar .cell.selected:hover {
    background-color: $primary !important;
    color: $white !important;
}

.vdp-datepicker__calendar .cell.selected {
    background-color: $primary !important;
    color: $white !important;
}