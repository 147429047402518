.btn-primary.disabled, .btn-primary:disabled {
    background-color: $btn-link-disabled-color !important;
    border-color: $btn-link-disabled-color !important;
}

.form-group.isRequired  label:after {
    content: '*';
    color: $danger;
    font-size: $h2-font-size;
    line-height: 0.5;
    padding-left: 2px;
}