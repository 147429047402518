// global styles imports

// page content
$headerHeight: rem(69px);

.PageContent {
    position: relative;
    min-height: calc(100vh - #{$headerHeight});
}

// loading spinner
$LoadingSpinnerSize: rem(50px);

.isLoading {
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($white, 0.7);
        z-index: $zindex-dropdown;
    }
    &::after {
        content: "";
        width: $LoadingSpinnerSize;
        height: $LoadingSpinnerSize;
        background: url(escape-svg("data:image/svg+xml,%3Csvg width='38' height='38' viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke='%23008CD2'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)' stroke-width='2'%3E%3Ccircle stroke-opacity='.5' cx='18' cy='18' r='18'/%3E%3Cpath d='M36 18c0-9.94-8.06-18-18-18'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 18 18' to='360 18 18' dur='1s' repeatCount='indefinite'/%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: $zindex-dropdown; 
    }
}