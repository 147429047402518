  // not responsive container
  .container {
    width: map-get($container-max-widths, "xl");
    max-width: map-get($container-max-widths, "xl");
  }

  body {
      max-width: 1920px;
      min-width: 1140px;
      margin: 0 auto;
  }