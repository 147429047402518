// Print styles

@media print {
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  header,
  footer {
    display: none;
  }

  .no-print {
    display: none;
  }
}
